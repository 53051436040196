<template>
  <div>
    <!--Modal AddEditProcesoElectoralMultimediaAlbun-->
    <div v-if="edit.mostrarModal">
      <add-edit-proceso-electoral-multimedia-album
        v-model="edit.mostrarModal"
        :proceso-electoral-multimedia-album-id="
          edit.procesoElectoralMultimediaAlbumId
        "
        @onSave="handleAddEditProcesoElectoralMultimediaAlbumSaved"
      ></add-edit-proceso-electoral-multimedia-album>
    </div>
    <!--Modal GaleriaMultimediaAlbumImagen-->
    <div v-if="viewGallery.mostrarModal">
      <galeria-multimedia-album-imagen
        v-model="viewGallery.mostrarModal"
        :proceso-electoral-multimedia-album-id="
          viewGallery.procesoElectoralMultimediaAlbumId
        "
      ></galeria-multimedia-album-imagen>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form>
          <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
              <div class="card-title">
                <h3 class="card-label">
                  <i class="fas fa-filter"></i> Filtros
                </h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="font-weight-bold">Tipo de Imagen</label>
                  <select
                    class="form-control"
                    v-model="filter.tipoMultimediaParamId"
                  >
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="tipoImagen in tipoImagenes"
                      :value="tipoImagen.parametroId"
                      :key="tipoImagen.parametroId"
                      >{{ tipoImagen.descripcion }}</option
                    >
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Descripción</label>
                  <b-form-input
                    id="input-1"
                    v-model="filter.descripcion"
                    placeholder="Descripcion..."
                  ></b-form-input>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Región</label>
                  <select class="form-control" v-model="filter.regionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="region in regiones"
                      :value="region.regionId"
                      :key="region.regionId"
                      >{{ region.nombre }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-md-4">
                  <label class="font-weight-bold"> Sub región</label>
                  <select class="form-control" v-model="filter.subRegionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="subRegion in subRegiones"
                      :value="subRegion.subRegionId"
                      :key="subRegion.subRegionId"
                      >{{ subRegion.nombre }}</option
                    >
                  </select>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Local de Votación</label>
                  <select class="form-control" v-model="filter.localVotacionId">
                    <option :value="null">- Seleccionar -</option>
                    <option
                      v-for="local in locales"
                      :value="local.localVotacionId"
                      :key="local.localVotacionId"
                      >{{ local.nombre }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div class="card-footer padding-t-b">
              <div class="col-md-12">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bold mr-2 alignment-right"
                  @click.prevent="fetchAlbumsMultimedia()"
                  ><i class="fa fa-search"></i> Buscar</a
                >
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label"><i class="fa fa-image"></i> Imágenes</h3>
            </div>
            <div class="card-toolbar" v-if="codigoRol == 'ADM'">
              <a
                href="#"
                class="btn btn-primary font-weight-bold mr-2"
                @click.prevent="doAddEditProcesoElectoralMultimediaAlbum()"
                ><i class="la la-plus"></i> Nuevo</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-hover"
                style="min-height: 150px;"
              >
                <thead>
                  <tr>
                    <th>
                      Local / Dirección
                    </th>
                    <th>
                      Sub Región / Región
                    </th>
                    <th>
                      Descripción
                    </th>
                    <th>
                      Imágenes
                    </th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="album in albums"
                    :key="album.procesoElectoralMultimediaAlbumId"
                  >
                    <td>
                      <span v-if="album.localVotacionNombre != null">
                        {{ album.localVotacionNombre }}<br />
                        <small class="text-muted">{{
                          album.localVotacionDireccion
                        }}</small>
                      </span>
                      <span
                        v-if="album.localVotacionNombre == null"
                        class="badge badge-light"
                        >No asociado</span
                      >
                    </td>
                    <td>
                      <span v-if="album.localVotacionNombre != null">
                        {{ album.localVotacionSubRegion }}<br />
                        <small class="text-muted">{{
                          album.localVotacionRegion
                        }}</small>
                      </span>
                      <span
                        v-if="album.localVotacionNombre == null"
                        class="badge badge-light"
                        >No asociado</span
                      >
                    </td>
                    <td>
                      <span
                        class="label label-sm label-light-primary label-inline label-rounded "
                        >{{ album.tipoParametroMultimedia }}</span
                      ><br />
                      {{ album.descripcion }}
                    </td>
                    <td>
                      <CoolLightBox
                        :items="album.coolboxImages"
                        :index="album.coolboxIndex"
                        :useZoomBar="true"
                        :slideshow="true"
                        @close="album.coolboxIndex = null"
                      >
                      </CoolLightBox>

                      <div
                        class="symbol-group symbol-hover"
                        v-if="album.symbolImages.length > 0"
                      >
                        <div
                          v-for="(imagen, imageIndex) in album.symbolImages"
                          :key="imagen.procesoElectoralMultimediaImagenId"
                          @click="album.coolboxIndex = imageIndex"
                        >
                          <div class="symbol symbol-90">
                            <img
                              alt=""
                              :src="apiResource(imagen.rutaArchivoThumb)"
                            />
                          </div>
                        </div>
                        <div
                          class="symbol symbol-90 symbol-circle symbol-light"
                          v-if="album.masImagenes"
                        >
                          <span
                            class="symbol-label font-weight-bold"
                            @click="album.coolboxIndex = 0"
                            >{{ album.masImagenes }}+</span
                          >
                        </div>
                        <!--
                        <div class="symbol symbol-30 symbol-circle symbol-light">
                          <span class="symbol-label font-weight-bold" @click.prevent="doGaleriaMultimediaAlbumImagen(album.procesoElectoralMultimediaAlbumId)">{{ album.cantFotosSubidas }}+</span>
                        </div>-->
                      </div>
                      <span v-if="album.symbolImages.length == 0"
                        ><i class="fa fa-image"></i> Vacío</span
                      >
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          href="#"
                          @click.prevent="
                            doAddEditProcesoElectoralMultimediaAlbum(
                              album.procesoElectoralMultimediaAlbumId
                            )
                          "
                          ><span class="navi-icon"
                            ><i class="la la-pen text-dark"></i> Editar</span
                          ></b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="
                            (album.procesoElectoralId != null &&
                              album.codigoParametroMultimedia ==
                                'Img_Local_Votacion') ||
                              (album.procesoElectoralId != null &&
                                album.codigoParametroMultimedia ==
                                  'Img_Ficha_Observacion')
                          "
                          href="#"
                          @click.prevent="
                            doFichaSeguimientoElectoralNewTab(
                              album.localVotacionId
                            )
                          "
                          ><span class="navi-icon"
                            ><i class="la la-clipboard-list text-dark"></i>
                            Ficha</span
                          ></b-dropdown-item
                        >
                        <b-dropdown-item
                          v-if="codigoRol == 'ADM'"
                          href="#"
                          @click.prevent="
                            downloadAlbumImagenes(
                              album.procesoElectoralMultimediaAlbumId
                            )
                          "
                          ><span class="navi-icon"
                            ><i class="fa fa-download fa-fw"></i>
                            Descargar</span
                          ></b-dropdown-item
                        >
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <b-card-footer>
            <b-pagination
              size="sm"
              v-model="pagination.page"
              :limit="10"
              :total-rows="pagination.totalCount"
              :per-page="pagination.pageSize"
              class="m-0"
            />
          </b-card-footer>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="download-modal"
        size="md"
        title="Descargar Imagenes"
        hide-footer
      >
        <div v-if="download.hasDownloadResult">
          <div class="mb-3">
            <p>
              Utilice el siguiente enlace para descargar o compartir el archivo
              de Imagenes:
            </p>
            <div class="card p-2 mb-2 border bg-light">
              {{ filesUrl + download.reportUrl }}
            </div>
            <a
              class="btn btn-default"
              :href="`${filesUrl + download.reportUrl}`"
              target="_blank"
              ><i class="fa fa-download fa-fw"></i> Descargar archivo</a
            >
          </div>
        </div>
        <!--<div slot="modal-footer" class="d-flex justify-content-between w-100">
        <div class="float-left"></div>
        <b-button variant="primary" class="float-right" @click="$bvModal.hide('preview-modal')">
          Cerrar
        </b-button>
      </div>-->
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.cell-width-10 {
  width: 10%;
}

.cell-width-60 {
  width: 60%;
}

.cell-width-20 {
  width: 20%;
}

.alignment-right {
  float: right;
}

.padding-t-b {
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import addEditProcesoElectoralMultimediaAlbum from "@/components/multimedia/add-edit-proceso-electoral-multimedia-album";
import galeriaMultimediaAlbumImagen from "@/components/multimedia/galeria-multimedia-album-imagen";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "list-proceso-electoral-multimedia",
  components: {
    addEditProcesoElectoralMultimediaAlbum,
    galeriaMultimediaAlbumImagen,
    CoolLightBox,
  },
  props: {},
  data: () => ({
    albums: [],
    edit: {
      mostrarModal: false,
      procesoElectoralMultimediaAlbumId: null,
    },
    viewGallery: {
      mostrarModal: false,
      procesoElectoralMultimediaAlbumId: null,
    },
    pagination: {
      page: 1,
      pageSize: 10,
      paginate: true,
    },
    filter: {
      localVotacionId: null,
      tipoMultimediaParamId: null,
      descripcion: "",
      regionId: null,
      subRegionId: null,
    },
    download: {
      hasDownloadResult: false,
      reportUrl: null,
    },
    tipoImagenes: [],
    regiones: [],
    subRegiones: [],
    locales: [],
    codigoRol: "",
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doAddEditProcesoElectoralMultimediaAlbum: function(
      procesoElectoralMultimediaAlbumId
    ) {
      this.edit.procesoElectoralMultimediaAlbumId = procesoElectoralMultimediaAlbumId;
      this.edit.mostrarModal = true;
    },
    doGaleriaMultimediaAlbumImagen: function(
      procesoElectoralMultimediaAlbumId
    ) {
      this.viewGallery.procesoElectoralMultimediaAlbumId = procesoElectoralMultimediaAlbumId;
      this.viewGallery.mostrarModal = true;
    },
    doFichaSeguimientoElectoralNewTab: function(localVotacionId) {
      let routeData = this.$router.resolve({
        name: "sheet-local-edit",
        params: {
          procesoElectoralId: this.$session.get("ProcesoElectoralId"),
          localVotacionId: localVotacionId,
        },
      });

      window.open(routeData.href, "_blank");
    },
    handleAddEditProcesoElectoralMultimediaAlbumSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchAlbumsMultimedia();
    },
    fetchAlbumsMultimedia: function() {
      var model = {
        pagination: this.pagination,
        ...this.filter,
        ProcesoElectoralId: this.$session.get("ProcesoElectoralId"),
      };

      //alert(JSON.stringify(model));
      var vm = this;
      this.$http
        .post(
          this.apiRoute(
            "MultimediaElectoralProcess",
            "ListProcesoElectoralMultimediaAlbums"
          ),
          model
        )
        .then(function(response) {
          var albumsTemp = response.body.data.items;

          for (var a = 0; a < albumsTemp.length; a++) {
            albumsTemp[a].coolboxIndex = null;
            albumsTemp[a].coolboxImages = albumsTemp[a].imagenes.map(function(
              x
            ) {
              return {
                //title: "In nature, nothing is perfect and everything is perfect",
                description: albumsTemp[a].descripcion,
                thumb: vm.apiResource(x.rutaArchivoThumb),
                src: vm.apiResource(x.rutaArchivo),
              };
            });

            albumsTemp[a].symbolImages = albumsTemp[a].imagenes.slice(0, 3);
            albumsTemp[a].masImagenes =
              albumsTemp[a].imagenes.length - albumsTemp[a].symbolImages.length;
          }

          this.albums = response.body.data.items;
          this.pagination = response.body.data.pagination;
          this.showLoading(false);
        });
    },
    fetchTipoImagenes: function() {
      var code = "TIPO_IMAGEN";
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: code,
          },
        })
        .then(function(response) {
          this.tipoImagenes = response.body.data;
        });
    },
    fetchRegiones: function() {
      var modelRegion = {};
      this.$http
        .post(this.apiRoute("Region", "ListRegiones"), modelRegion)
        .then(function(response) {
          this.regiones = response.body.data.items;
        });
    },
    fetchSubRegiones: function(regionId) {
      this.$http
        .get(this.apiRoute("Region", "ListSubRegionesPorRegion"), {
          params: {
            RegionId: regionId,
          },
        })
        .then(function(response) {
          this.subRegiones = response.body.data.items;
        });
    },
    fetchLocales: function(subRegionId) {
      this.$http
        .get(this.apiRoute("Region", "ListLocalesVotacionPorSubRegion"), {
          params: {
            SubRegionId: subRegionId,
          },
        })
        .then(function(response) {
          this.locales = response.body.data.items;
        });
    },
    downloadAlbumImagenes: function(procesoElectoralMultimediaAlbumId) {
      this.showLoading();
      this.download.hasDownloadResult = false;

      this.$http
        .get(
          this.apiRoute(
            "MultimediaElectoralProcess",
            "DownloadProcesoElectoralMultimediaAlbumById"
          ),
          {
            params: {
              ProcesoElectoralMultimediaAlbumId: procesoElectoralMultimediaAlbumId,
            },
          }
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.download.hasDownloadResult = true;
            this.download.reportUrl = response.body.data.reportUrl;
            this.$bvModal.show("download-modal");
            this.showLoading(false);
          }
        }, this.mostrarMensajeExcepcionVueResource);
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {
    "pagination.page": function(newValue, oldValue) {
      this.showLoading(true);
      this.fetchAlbumsMultimedia(newValue);
    },
    "filter.regionId": function(newValue, oldValue) {
      this.fetchSubRegiones(newValue);
    },
    "filter.subRegionId": function(newValue, oldValue) {
      this.fetchLocales(newValue);
    },
  },
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Imágenes" }]);
    this.codigoRol = this.$session.get("CodigoRol");
    this.fetchRegiones();
    this.fetchTipoImagenes();
    this.fetchAlbumsMultimedia();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
